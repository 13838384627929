export const Constant = {
    messageStatus: {
        success: 'success',
        info: 'info',
        warn: 'warn',
        error: 'warn',
    },
    response: {
        isSuccess: 'isSuccess',
        message: 'message',
        data: 'data',
        exception: 'exception',
    },
    auths: {
        isLoginIn: 'loggedIn',
        token: 'token',
        userId: 'userId',
        userName: 'userName',
        currentUser: 'currentUser',
        fullName: 'fullName',
        tokenFirebase: 'tokenFirebase',
        datetimeLogin: 'datetimeLogin',
        expires: 'expires'
    }
};
