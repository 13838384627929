import { Component } from '@angular/core';
import { MessageService } from 'primeng';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styles: []
})
export class AppComponent {
  constructor(
    private messageService: MessageService
  ) {}

  onConfirm() {
    this.messageService.clear('custom-message');
  }
}
