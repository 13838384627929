import { HandleError } from '../../infrastructure/handleError';
import { MsgService } from '../local/msg.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ResponseModel } from '../../models/models/response.model';
import { Observable } from "rxjs/Observable";
// @Injectable()
export class BaseService extends HandleError {
    constructor(
        protected messageService: MsgService,
        protected httpClient: HttpClient,
        protected urlName: string,
        protected apiName: string
    ) {
        super(messageService);
    }

    async getCustomApi(apiMethod: string, params: HttpParams): Promise<ResponseModel> {
        return await this.httpClient.get<ResponseModel>(`${this.urlName}/${this.apiName}/${apiMethod}`, { params }).toPromise();
    }

    async postCustomApi(apiMethod: string, model: object): Promise<ResponseModel> {
        return await this.httpClient.post<ResponseModel>(`${this.urlName}/${this.apiName}/${apiMethod}`, model).toPromise();
    }
    public getCustomApiPaging(apiMethod: string, arrCols: string[] = [], params = new HttpParams(), pageSize: number = 0, pageNumber: number = 0): Observable<ResponseModel> {
        let cols = null;

        if (arrCols) {
            cols = arrCols.join(',');
        }

        if (!params) params = new HttpParams();

        if (pageSize)
            params = params.append("pageSize", pageSize + "");
        if (pageNumber)
            params = params.append("pageNumber", pageNumber + "");
        if (cols)
            params = params.append("cols", cols);

        return this.httpClient.get<ResponseModel>(`${this.urlName}/${this.apiName}/${apiMethod}`, { params: params });
    }
}
