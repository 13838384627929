import { NgModule } from '@angular/core';
import { Routes, RouterModule, NoPreloading } from '@angular/router';
import { Page404Component } from './shared/components/page404/page404.component';
import { AuthGuard } from './shared/guard/auth.guard';
import { AuthLoginGuard } from './shared/guard/authLogin.guard';
import { Page403Component } from './shared/components/page403/page403.component';


const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: '',
    loadChildren: () =>
      import('./app-secured/app-secured.module').then(m => m.AppSecuredModule), canActivate: [AuthGuard], canActivateChild: [AuthGuard]
  },
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule), canActivate: [AuthLoginGuard] },
  { path: 'forgot-password', loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule), canActivate: [AuthLoginGuard] },
  { path: 'register', loadChildren: () => import('./register/register.module').then(m => m.RegisterModule), canActivate: [AuthLoginGuard] },

  { path: '404', component: Page404Component },
  { path: '403', component: Page403Component },
  { path: '**', redirectTo: '404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', preloadingStrategy: NoPreloading })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
