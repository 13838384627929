import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MsgService } from '../local/msg.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { StorageService } from '../local/storage.service';
import { Router } from '@angular/router';
import { ChangePasswordModel } from '../../models/models/changePassword.model';
import * as jwt_decode from 'jwt-decode';
import { ResponseModel } from '../../models/models/response.model';
import { LoginModel } from '../../models/models/login.model';
import { User } from '../../models/models/user.model';
import { Constant } from '../../infrastructure/constant';
import { GeneralService } from './general.service';
import { Customer } from '../../models/models/customer.model';
import { RegisterModel } from '../../models/models/register.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends GeneralService {

  constructor(
    protected messageService: MsgService,
    protected httpClient: HttpClient,
    // protected persistenceService: PersistenceService,

    private storageService: StorageService,
    private router: Router
  ) {
    super(messageService, httpClient, environment.apiUrl, 'Account');
  }

  async login(model: LoginModel): Promise<ResponseModel> {
    const res = await this.postCustomApi('SignIn', model);

    if (res.isSuccess) {
      this.storageService.set(Constant.auths.expires, res.data.expires);
      this.storageService.set(Constant.auths.isLoginIn, 'true');
      this.storageService.set(Constant.auths.token, res.data.token);
      this.storageService.set(Constant.auths.userId, res.data.userId);
      this.storageService.set(Constant.auths.userName, res.data.userName);
      this.storageService.set(Constant.auths.fullName, res.data.userFullName);
    }

    return res;
  }

  async logout() {
    this.storageService.removeAll();
    this.router.navigate(['/']);
  }

  isLogged() {
    const token = this.storageService.get(Constant.auths.token);
    if (token) {
      const jwt = jwt_decode(token);
      if (jwt.exp * 1000 > new Date().valueOf()) {
        return true;
      }
    }
    return false;
  }

  async changePassWord(model: ChangePasswordModel) {
    model.userId = this.getUserId() as any;

    const res = await this.postCustomApi('changePassWord', model);
    if (!this.isValidResponse(res)) { return; }

    return res;
  }

  async getCurrentInfo(): Promise<Customer> {
    const params = new HttpParams();
    const res = await this.getCustomApi('GetCurrentInfo', params);
    if (!this.isValidResponse(res)) { return; }
    return res.data;
  }

  async checkEmail(email: any): Promise<ResponseModel> {
    let params = new HttpParams();
    params = params.append('email', email);
    const res = await this.getCustomApi('CheckEmail', params);
    return res;
  }

  async checkNumberPhone(numberPhone: any): Promise<ResponseModel> {
    let params = new HttpParams();
    params = params.append('numberPhone', numberPhone);
    const res = await this.getCustomApi('CheckNumberPhone', params);
    return res;
  }

  getUserId(): string {
    return this.storageService.get(Constant.auths.userId);
    // return this.persistenceService.get(Constant.auths.userId, StorageType.LOCAL);
  }

  getUserName(): string {
    return this.storageService.get(Constant.auths.userName);
  }

  getFullName(): string {
    return this.storageService.get(Constant.auths.fullName);
  }

  async forgotPassword(model: LoginModel): Promise<ResponseModel> {
    const res = await this.postCustomApi('ForgotPassword', model);
    if (res.isSuccess) {}
    return res;
  }

  async register(model: RegisterModel) {
    const res = await this.postCustomApi('Register', model);
    return res;
  }

}
